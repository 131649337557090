import Link from 'next/link';
import React from 'react';
import LargeWidthContainer from '../Layout/Containers/LargeWidthContainer';
import styles from './footer.module.scss';

const currentYear = () => new Date().getFullYear();

const Footer = () => {
    return (
        <footer id="footer" className={styles.footer}>
            <LargeWidthContainer>
                <div className={styles.location}>
                    <ul className={styles.leftAlignedList}>
                        <li className={styles.state}>Oslo</li>
                        <li>Prinsensgate 22, 0157</li>
                        <li>Oslo, Norway</li>
                    </ul>
                    <ul className={styles.leftAlignedList}>
                        <li className={styles.state}>Bergen</li>
                        <li>Christian Michelsens gate 6 B, 5012</li>
                        <li>Bergen, Norway</li>
                    </ul>
                    <ul className={styles.leftAlignedList}>
                        <li className={styles.state}>Sandefjord</li>
                        <li>Skiringssalveien 9, 3211</li>
                        <li>Sandefjord, Norway</li>
                    </ul>
                    <ul className={styles.leftAlignedList}>
                        <li className={styles.state}>Hồ Chí Minh</li>
                        <li>17-17A-19 Tôn Thất Tùng</li>
                        <li>Quận 1 – Hồ Chí Minh</li>
                        <li>Vietnam</li>
                    </ul>
                </div>
                <div className={styles.socials}>
                    <ul>
                        <li>
                            <a
                                className={styles.facebook}
                                href="https://www.facebook.com/noriagroup.no/"
                            >
                                Facebook
                            </a>
                        </li>
                        <li>
                            <a
                                className={styles.instagram}
                                href="https://www.instagram.com/noria_norway/"
                            >
                                Instagram
                            </a>
                        </li>
                        <li>
                            <a
                                className={styles.linkedin}
                                href="https://www.linkedin.com/company/noria-as/"
                            >
                                LinkedIn
                            </a>
                        </li>
                    </ul>
                </div>
                <div className={styles.copy}>
                    <p>
                        All Rights Reserved © {currentYear()}&nbsp;/&nbsp;
                        <Link href={`/privacy`} title="Go to the Privacy and Cookie policy page">
                            <span>Privacy and Cookie policy</span>
                        </Link>
                        &nbsp;/&nbsp;
                        <Link
                            href={`/documents/Transparency_Report_2024.pdf`}
                            title="Transparency report"
                        >
                            <span>Transparency Report</span>
                        </Link>
                    </p>
                </div>
            </LargeWidthContainer>
        </footer>
    );
};

export default Footer;
